





































import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { Validations } from 'vuelidate-property-decorators';
import { authPasswordValidation } from '@/app/modules/auth/config/authValidation';
import AuthPasswordDataInterface from '@/app/modules/auth/interfaces/AuthPasswordDataInterface';
import PasswordField from '@/shared/resources/components/forms/fields/PasswordField.vue';

@Component({
  components: {
    PasswordField,
    Button,
  },
})
export default class AuthRegisterPasswordForm extends Vue {
  /**Ó
   * Validations
   */
  @Validations()
  private get passwordRules() {
    return authPasswordValidation();
  }

  /**
   * Props
   */
  @Prop() private data!: AuthPasswordDataInterface;
  @Prop() private loading!: boolean;

  /**
   * Methods
   */
  private submit() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.$emit('submit');
    }
  }

  /**
   * Handlers
   */
  private onSubmit(e: Event) {
    e.preventDefault();
    this.submit();
  }
}
